import React from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import BasicBookmarks from '../components/bookmarks/basicBookmarks'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'
import { Seo } from '../components/seo/Seo'

const Bookmarks: React.FC = () => {
  const currentSlug = '/en/code'
  const currentLocale: AppLocale = GenerateLocales.en
  const seoTitle = 'My Area'
  const seoDescription = 'Personal area on hoch und weit'
  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })
  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <LayoutWrapper
        currentTitle="Code"
        currentLocale={currentLocale}
        currentSlug={currentSlug}
        otherLocales={[{ locale: GenerateLocales.de, slug: '/code/' }]}
        currentPath={currentPagePath}
      >
        <BasicBookmarks />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/code/`} />
}

export default Bookmarks
